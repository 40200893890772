class Points {

  init() {
    this.fightExpandButton = $('.tableExpandButton');
    this.fightExpandButton.on('click', points.fightExpandButtonClick);
  };

  fightExpandButtonClick() {
    var $this = $(this);
    var fight_id = $(this).data('fight-id');

    if ($this.attr('aria-expanded') == 'false') {
      toastr.info('Loading Fight...');
      $('#fight_' + fight_id + '_details').load('/fights/' + fight_id + '/ajax_strength_of_schedule', {}, function(response, status, xhr) {
        if (status == 'error') {
          toastr.error('Unable To Load Fight\'s Strength Of Schedule.');
        } else {
          $this.attr('aria-expanded', 'true');
          $('#fight_' + fight_id + '_details').removeAttr('hidden');
        }
      });
    } else {
      $this.attr('aria-expanded', 'false');
      $('#fight_' + fight_id + '_details').attr('hidden', '');
    }
  };

}

export let points = new Points();