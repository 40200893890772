class Tooltip {

  init() {
    // Special case for the standings page to avoid the tooltips being cutoff at the bottom of the table
    if ($('.standings').length) {
      let wrapperRect;
      let tooltipElements;

      // get page scroll position
      let scrollY = window.scrollY;

      // get list of table wrappers
      let wrappers = document.querySelectorAll('.tableWrapper');

      // loop through wrappers to set up any tooltips
      wrappers.forEach(function(wrapper) {
          // get node list of elements within wrapper with tooltip attribute
          tooltipElements = wrapper.querySelectorAll('[data-tooltip]');

          // measure wrapper
          wrapperRect = wrapper.getBoundingClientRect();

          // calculate position where tooltip extends beyond bottom border of wrapper
          let yMax = scrollY + wrapperRect.top + wrapperRect.height;

          // loop through elements with tooltip attr and set them up
          tooltipElements.forEach(function(tooltipElement) {

              // get tooltip text
              let content = tooltipElement.dataset.tooltip;

              // add class to tooltip container
              tooltipElement.classList.add('tooltip');

              // create tooltip element to insert into DOM
              let frag = document.createDocumentFragment();
              let div = document.createElement('div');
              div.innerHTML = content.replace(/nl;/g,'<br/>');
              div.classList = 'tooltip--content';
              frag.appendChild(div);

              // add event for user's mouse to enter container
              tooltipElement.addEventListener('mouseenter',function(e) {
                  // add class
                  e.target.classList.add('__visible');
              });

              // add event for user's mouse to leave container
              tooltipElement.addEventListener('mouseleave',function(e) {
                  // remove class
                  e.target.classList.remove('__visible');
              });

              // insert tooltip element into DOM
              tooltipElement.appendChild(frag);

              // measure tooltip
              let divRect = div.getBoundingClientRect();

              // calculate tooltip position and size
              let divMaxY = divRect.top + divRect.height + scrollY;

              // check if tooltip extends beyond wrapper element
              // if it does then add class
              if(divMaxY >= yMax) div.classList.add('tooltip--above');
          });
      });

    // Any other tooltips supporting dynamic content
    } else {
      $('body').on('mouseenter', '[data-tooltip]', function(){
        $(this).find('.tooltip--content').remove();
        let frag = document.createDocumentFragment();
        let div = document.createElement('div');
        div.innerHTML = $(this).data('tooltip').replace(/nl;/g,'<br/>');
        div.classList = 'tooltip--content';
        frag.appendChild(div);
        $(this).append(frag);

        $(this).addClass('__visible');
      });
      $('body').on('mouseleave', '[data-tooltip]', function(){
        $(this).removeClass('__visible');
      });
    }
  };
}

export let tooltip = new Tooltip();